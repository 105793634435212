import * as React from "react";
import { createPortal } from "react-dom";

import '../../styles.css';

const Modal = ({ isVisible, hideModal, score, time, team, fscore }) => {

  return isVisible? 
    createPortal(
        <React.Fragment>
          <div className="modal-overlay"></div>
          <div className="modal-wrapper"
            aria-modal={true}
            aria-hidden={true}
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div className="modal-header">
                <h1 className="modal-title">Final Score</h1>
              </div>
              <div className="modal-body">
                <h2 className="modal-description">
                  Team: {team}
                </h2>
                <h2 className="modal-description">
                  Points: {score}
                </h2>
                <h2 className="modal-description">
                  Time Left: {time}ms
                </h2>
                <h2 className="modal-description final-score">
                  Score: {fscore}
                </h2>
              </div>
              {/* <button className="modal-button" onClick={hideModal}>
                Start New Game
              </button> */}
            </div>
          </div>
        </React.Fragment>,
        document.body,
    )
    : null;
};

export default Modal;