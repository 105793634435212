import React, { useEffect, useState } from "react";
import "./styles.css";
// import data from "./data.json";

import Modal from "./components/Modal/Modal";
import useModal from "./components/Modal/useModal";
import ScoreboardList from "./components/Scoreboard/ScoreboardList";

function App() {
  const [imagesArray, setImagesArray] = useState([])
  const [openedCard, setOpenedCard] = useState([]);
  const [matched, setMatched] = useState([]);
  const [points, setPoints] = useState(0);

  const [[mins, secs, msecs], setTime] = useState([5, 0, 0]); //set default time ------------------
  // const [[mins, secs, msecs], setTime] = useState([0, 10, 0]); //set default time TEST (10 seconds)------------------
  const [start, setStart] = useState(false);

  const {isVisible, toggleModal} = useModal();

  const [teamName, setTeamName] = useState('');

  const [showCards, setShowCards] = useState(false);
  
  const [scoreboard, setScoreboard] = useState([]);

  const emojis = [
    {id: 1, name: '01mod'},
    {id: 2, name: '02mod'},
    {id: 3, name: '03ood'},
    {id: 4, name: '04cbm'},
    {id: 5, name: '05brb'},
    {id: 6, name: '06ooo'},
    {id: 7, name: '07qj'},
    {id: 8, name: '08zoom'},
    {id: 9, name: '09ns'},
    {id: 10, name: '10oracle'},
    {id: 11, name: '11oracle'},
    {id: 12, name: '12meeting'},
    {id: 13, name: '13blobthumbsup'},
    {id: 14, name: '14maple'},
    {id: 15, name: '15wifi'},
    {id: 16, name: '16oracle'},
    {id: 17, name: '17phone'},
    {id: 18, name: '18laptop'},
    {id: 19, name: '19sandwich'},
    {id: 20, name: '20sunrise'},
    {id: 21, name: '21calendar'},
    {id: 22, name: '22zzz'},
    {id: 23, name: '23shh'},
    {id: 24, name: '24lolface'},
    {id: 25, name: '25no_entry'},
    {id: 26, name: '26bento'},
    {id: 27, name: '27here'},
    {id: 28, name: '28brb'},
    {id: 29, name: '29away'},
    {id: 30, name: '30memo'},
    //-----------------------------------------------------
    // {id: 31, name: '31sunrise_over_mountains'},
    // {id: 32, name: '32together_netsuite'},
    // {id: 33, name: '33circle'},
    // {id: 34, name: '34circle'},
    // {id: 35, name: '35circle'}
  ];

  //Get Team Name-----------------------------------------------------------------------------------------------------
  const updateTeamName = (e) => {
    const val = e.target.value;
    setTeamName(val);
  }

  const teamNameSet = () => {
    if (teamName === '') {
      window.alert('Fill up Team Name to start the game!');
    } else {
      setShowCards(true);
    }
  }

  //Get Team Name-----------------------------------------------------------------------------------------------------

  const saveScore = () => {
    let copy = [...scoreboard];
    copy = [...copy, {team: teamName, score: points, time: timePoints, final: finalScore.toFixed(0)}];
    setScoreboard(copy);
  }

  useEffect(() => setScoreboard(scoreboard.sort((a, b) => (a.final > b.final) ? -1 : 1)), [scoreboard]);

  //Shuffle Card Set-----------------------------------------------------------------------------------------------------
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
  }

  //Card Set (Shuffled)-----------------------------------------------------------------------------------------------------
  function createCardBoard() {
    const imagesGenerated = emojis?.concat(...emojis)
    const shuffledArray = shuffleArray(imagesGenerated)
    setImagesArray(shuffledArray)
  }
  
  useEffect(() => {
    createCardBoard();
  }, []);
 
  //Card is flipped-----------------------------------------------------------------------------------------------------
  const flipCard = index => {
    setStart(true);
    // setOpenedCard((opened) => [...opened, index]);
    setTimeout(() => setOpenedCard((opened) => [...opened, index]), 50) // put delay on opening tile
  }

  //Countdown Timer START-----------------------------------------------------------------------------------------------------
  const tick = () => {
    if (start) {
      if (mins === 0 && secs === 0 && msecs === 0){
        saveScore();
        setStart(false);
        toggleModal();
      }            
      else if (secs === 0 && msecs === 0) {
          setTime([mins - 1, 59, 99]);
      } 
      else if (msecs === 0) {
          setTime([mins, secs - 1, 99]);
      } 
      else {
          setTime([mins, secs, msecs - 1]);
      }
    }
  };

  let timerColor = '#2d3956';
  if (mins <= 0 && secs <= 59 && msecs <= 99) timerColor = '#f10c0c';
  else if (mins <= 1 && secs <= 59 && msecs <= 99) timerColor = '#f18a0c';      

  useEffect(() => {
      const interval = setInterval(() => tick(), 10);
      return () => clearInterval(interval);
  }); 

  //Computing the milliseconds left  
  let timePoints;

  if (secs > 0) {
    let secsComputed = secs * 100;
    timePoints = secsComputed + msecs
  } else {
    timePoints = msecs
  }

  //Matching and adding points-----------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (openedCard < 2) return;

    const firstMatched = imagesArray[openedCard[0]];
    const secondMatched = imagesArray[openedCard[1]];

    if (openedCard.length === 2) setTimeout(() => setOpenedCard([]), 450); // delay on closing tile

    if (secondMatched && firstMatched.id === secondMatched.id) {
      setMatched([...matched, firstMatched.id]);
      setPoints(points => points + 1) // add points every matched pair
    }
  }, [openedCard]);

  //Points counter and stop the timer-----------------------------------------------------------------------------------------------------
  useEffect(() => {
    if(points === emojis.length) { // points will depend the number of tiles
      saveScore();
      setStart(false);
      toggleModal();
    }
  }, [points]);

  //Final Score-----------------------------------------------------------------------------------------------------

  let finalScore = (points * 500) + (timePoints/2);

  //Reset All-----------------------------------------------------------------------------------------------------

  const resetAll = () => {
    setTeamName('');
    setTime([5,0,0]); //reset default time ------------------
    // setTime([0,10,0]); //set default time TEST (10 seconds)------------------
    setPoints(0);
    setMatched([]);
    setOpenedCard([]);
    createCardBoard();
    setShowCards(false);
    toggleModal();
  }

  return (
    <div className='App'>
      <div>
        <Modal isVisible={isVisible} hideModal={resetAll} time={timePoints} score={points} team={teamName} fscore={finalScore.toFixed(0)}/>
      </div>
      <div className="header-container">
        <h1>SuiteMemory</h1>
      </div>
      <input style={{display: !showCards? 'flex' : 'none'}} type="text" value={teamName} onChange={e => updateTeamName(e)} onBlur={teamNameSet} placeholder="Team Name"/>
      {
        !showCards ? '':
        <div className="game">
          <div className="details-container">
            <div className="team">
              <h1>{`Team ${teamName}`}</h1>
            </div>
            <div className="points">
              <h1>Points: {points}</h1>
            </div>
            <div className="timer">
              <h1 style={{color: timerColor}}>{`${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}.${msecs.toString().padStart(2, '0')}`}</h1> 
            </div>
          </div>
          <div className="cards-container">
            <div className='cards'>
              { imagesArray.map((emoji, index) => {
                let isFlipped = false;

                if (openedCard.includes(index)) isFlipped = true;
                if (matched.includes(emoji.id)) isFlipped = true;

                return (
                  <div 
                    className={`emoji-card ${isFlipped ? "flipped" : ""} `}
                    key={index}
                    onClick={() => flipCard(index)}>
                    <div className='inner'>
                      <div className='front'>
                        <img src={`./img/${emoji.name}.png`} alt={`${emoji.name}`} width='100' />
                      </div>
                      <div className='back'></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      }
      
      {/* { scoreboard.length === 0 || showCards ? '' 
        :
        <div className="scoreboard">
        <div className="scoreboard-container">
            <div className="scoreboard-header">
              <div className="scoreboard-row">
                <h1 className="scoreboard-title">SCOREBOARD</h1>
              </div>
            </div>
            <table className="scoreboard-body">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Team</th>
                  <th>Points</th>
                  <th>Time Left</th>
                  <th>Score</th>
                </tr>
              </thead> 
              <tbody>
                {scoreboard.map((s, i) => <ScoreboardList key={i} line={i+1} team={s.team} score={s.score} time={s.time} fscore={s.final}/>)} 
              </tbody>
            </table>
          </div>
        </div>
      } */}
      </div>
  );
}

export default App;
